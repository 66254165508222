@import "src/styles/mixin";

.list {
  flex-grow: 1;
  margin-right: 16px;

  @media only screen and (min-width: 320px) and (max-width: 1365.98px) {
    margin-right: 0;
    margin-bottom: 60px;
  }

  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    margin-bottom: 0;
  }
}

.accordion {
  min-width: 310px;
  height: 469px;
  padding-right: 10px;
  overflow-x: hidden;
  @include customScroll;

  &Panel {
    padding-bottom: 20px;
  }

  @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
    height: 340px;
  }
}

.input {
  padding: 16px 20px 8px;
  border: none;

  &Wrap {
    margin-bottom: 30px;
  }
}
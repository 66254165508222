@import "/src/styles/mixin";

.title {
  @include font30;
  margin-bottom: 40px;
}

.subtitle {
  @include font24;
  margin-bottom: 24px;
}

.contacts {
  &About {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    @media only screen and (min-width: 320px) and (max-width: 1365.98px) {
      flex-direction: column;
      gap: 70px;
    }

    @media only screen and (min-width: 320px) and (max-width: 767.98px) {
      gap: 60px;
    }
  }
}

.requisites {
  &List {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;


    @media only screen and (min-width: 320px) and (max-width: 767.98px) {
      flex-direction: column;
    }
  }

  &Item {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 465px;
    padding: 12px 12px;
    border: 1px solid var(--grey-lines-color);
    border-radius: var(--border-radius-normal);
    transition: all .25s ease;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
    }

    &Title {
      @include font18;
      margin-bottom: 30px;

      @media only screen and (min-width: 320px) and (max-width: 767.98px) {
        margin-bottom: 0;
      }
    }

    &Text {
      @include font16;
    }

    // &:not(:last-child) {
    //   margin-right: 165px;
    // }

    @media only screen and (min-width: 320px) and (max-width: 767.98px) {
      // &:first-child {
      // margin-bottom: 40px;
      // }

      // &:not(:last-child) {
      //   margin-right: 0;
      // }
    }
  }
}

.social {
  &List {
    display: flex;
    gap: 30px;

    @media only screen and (min-width: 320px) and (max-width: 767.98px) {
      gap: 16px;
    }
  }

  &Link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: var(--border-radius-small);
    transition: border-color 0.2s ease;
    border: 1px solid var(--secondary-color);

    &:hover {
      border-color: var(--grey-lines-color);
    }

    @media only screen and (min-width: 320px) and (max-width: 767.98px) {
      width: 30px;
      height: 30px;
    }
  }
}